export const OB = {
  id: '333643',
  name: 'OB',
  variants: ['30616'],
  variantOpenBanking: '30616',
};

export const PPT = {
  id: '343023',
  name: 'PPT enable',
  variants: ['44284'],
  variantPPTDisabled: '44284',
};

export const CC_LANDING = {
  id: '344219',
  name: 'Credit Card Landing Page',
  variants: ['46194', '46195'],
  control: '46194',
  treatment: '46195',
};

export const CUCUTA = {
  id: '344221',
  name: 'CUCUTA enable',
  46199: ['46199'],
  cucutaEnable: '46199',
};

export const INCREASE_CLARITY = {
  id: '342751',
  name: 'Increase Clarity on Product Choice',
  variants: ['43891', '43892'],
  control: '43891',
  treatment: '43892',
};

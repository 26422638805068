import React, {
  createContext, useState, useCallback, useRef,
} from 'react';
import PropTypes from 'prop-types';

const SiteContext = createContext();

const SiteContextProvider = ({
  children,
  defaultValue,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [prospectEmail, setProspectEmail] = useState('');
  const [isRegistrationFormOpen, setIsRegistrationFormOpen] = useState(false);
  const [
    usePurpleAsBackgroundInRegistrationForm,
    setUsePurpleAsBackgroundInRegistrationForm,
  ] = useState(false);
  const [isSavingsWaitlistFormOnError, setIsSavingsWaitlistFormOnError] = useState(false);
  const screenLoadingTime = useRef(0);
  const loadingTime = useRef(0);

  const closeRegistrationForm = useCallback(() => {
    setIsRegistrationFormOpen(false);
  }, []);

  const toggleRegistrationForm = useCallback(() => {
    setIsRegistrationFormOpen(prevValue => !prevValue);
  }, []);

  const toggleMenu = useCallback(() => {
    setIsMenuOpen(prevValue => !prevValue);
    setIsRegistrationFormOpen(false);
  }, []);

  const closeMenu = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  const startRealtimeAnalysis = useCallback(() => {
    screenLoadingTime.current = Date.now();
  }, []);

  const finishRealtimeAnalysis = useCallback(() => {
    loadingTime.current = Date.now() - screenLoadingTime.current;
  }, []);

  return (
    <SiteContext.Provider value={{
      ...defaultValue,
      loadingTime,
      isMenuOpen,
      toggleMenu,
      closeMenu,
      startRealtimeAnalysis,
      finishRealtimeAnalysis,
      isRegistrationFormOpen,
      closeRegistrationForm,
      toggleRegistrationForm,
      prospectEmail,
      setProspectEmail,
      usePurpleAsBackgroundInRegistrationForm,
      setUsePurpleAsBackgroundInRegistrationForm,
      isSavingsWaitlistFormOnError,
      setIsSavingsWaitlistFormOnError,
      ...defaultValue,
    }}
    >
      {children}
    </SiteContext.Provider>
  );
};

SiteContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  defaultValue: PropTypes.shape({
    email: PropTypes.string,
    setProspectEmail: PropTypes.func,
  }),
};

SiteContextProvider.defaultProps = {
  defaultValue: undefined,
};

export {
  SiteContextProvider,
  SiteContext,
};
